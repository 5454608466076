.BroadSignCreationModal {
  .Modal__content {
    max-width: 800px;
    width: 100%;
    margin: 20px;
    max-height: 95vh;
    overflow-y: auto;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  &__image {
    max-height: 400px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    gap: 60px;
  }

  &__header {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
  }

  &__details-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 26px;

    // this is just a first column
    p:nth-child(odd) {
      font-weight: bold;
    }
  }
}
